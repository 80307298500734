import { Alert, ClickAwayListener } from '@mui/material'

type ErrorAlertProps = {
    message: string
    setError: (message: string) => void
}

export const ErrorAlert = ({ props }: { props: ErrorAlertProps }) => {
    return (
        <ClickAwayListener onClickAway={() => props.setError('')}>
            <Alert severity="error">{props.message}</Alert>
        </ClickAwayListener>
    )
}
