import React, { FC, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { NodeService } from '../libs/ambient_api/NodeService'
import { CreateNodeRequest } from '../types/ApiRequests'
import { Node } from '../types/TechnicalTypes'
import { CopyBlock, dracula } from 'react-code-blocks'
import {
    Button,
    Backdrop,
    Box,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Modal,
    TextField,
    Typography,
    Link,
    SelectChangeEvent,
    Icon,
    IconButton,
    Tooltip,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { TokenResponse } from '../types/TechnicalTypes'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

interface AddNodePanelProps {
    onClose: () => void
    token: string
}

const AddNodePanel: FC<AddNodePanelProps> = ({ onClose, token }) => {
    const theme = useTheme()
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        role: '',
        architecture: '',
        cluster: null,
    })
    const [showWaitingScreen, setShowWaitingScreen] = useState(false)
    const [showSuccessScreen, setShowSuccessScreen] = useState(false)
    const [newNode, setNewNode] = useState<Node | null>(null)
    const [nodeTokenResponse, setNodeTokenResponse] =
        useState<TokenResponse | null>(null)
    const [copied, setCopied] = React.useState(false)

    const handleCopy = () => {
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    const handleChange = (
        e: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => {
        const { name, value } = e.target
        if (name) {
            setFormData({
                ...formData,
                [name]: value as string,
            })
        }
    }
    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target
        if (name) {
            setFormData({
                ...formData,
                [name]: value,
            })
        }
    }

    const validate = (): boolean => {
        if (
            !formData.name ||
            !formData.description ||
            !formData.role ||
            !formData.architecture
        ) {
            alert('Required fields are empty')
            return false
        }
        return true
    }

    const handleSubmit = async () => {
        // validate form
        // console.log('Validating form...')
        if (!validate()) {
            return
        }

        // log data
        // console.log('Form Data:', formData)
        // show waiting screen
        // console.log('Showing waiting screen...')
        setShowWaitingScreen(true)

        // add node
        console.log('Adding node...')
        if (token) {
            await addNode(token) // Wait for node to be added
        } else {
            throw new Error('Token not found')
        }

        setShowWaitingScreen(false) // Hide waiting screen
        setShowSuccessScreen(true) // Show success screen
    }

    const addNode = async (token: string) => {
        const createNodeRequest: CreateNodeRequest = {
            name: formData.name,
            description: formData.description,
            role: formData.role,
            architecture: formData.architecture,
            cluster: formData.cluster!,
        }
        const nodeService = new NodeService()
        const node = await nodeService.createNode(token, createNodeRequest)
        // setNewNode(node)
        const tokenResponse = await nodeService.requestNewDeviceAuth(
            token,
            node.id
        )
        setNodeTokenResponse(tokenResponse)
        const fetchedNode = await nodeService.getNode(
            token,
            tokenResponse.node_id
        )
        setNewNode(fetchedNode)
        // console.log('Node created: ', node)
    }

    const code = `mkdir ~/.ambient
cd ~/.ambient

# create virutal envrionment
python3 -m venv .venv
source .venv/bin/activate
pip install ambient_edge_server==1.2.2 ambientctl==1.2.2
ambientctl onboarding begin
`

    const waitingScreen = (
        <Backdrop
            open={true}
            style={{ color: '#fff', zIndex: theme.zIndex.drawer + 1 }}
        >
            <CircularProgress color="inherit" />
            <Typography style={{ marginLeft: '15px' }}>
                Adding node...
            </Typography>
        </Backdrop>
    )

    const successScreen = (
        <Backdrop
            open={true}
            style={{
                color: '#fff',
                zIndex: theme.zIndex.drawer + 1,
                flexDirection: 'column',
                backgroundColor: 'rgba(0, 0, 0, 1)',
            }}
        >
            <Typography variant="h3" gutterBottom>
                🎉 Node added successfully! 🎉
            </Typography>
            <Typography variant="h6" style={{ marginTop: '20px' }}>
                Run these commands on your linux edge device to start the
                onboarding process:
            </Typography>
            <Box style={{ marginTop: '10px', width: '80%' }}>
                <CopyBlock
                    {...({
                        text: code,
                        language: 'bash',
                        showLineNumbers: true,
                        theme: dracula,
                        codeBlock: true,
                    } as any)}
                />
            </Box>

            <Typography variant="h6" style={{ marginTop: '20px' }}>
                When prompted, enter the following node ID and token:
            </Typography>
            <Typography variant="h6" style={{ marginTop: '10px' }}>
                Node ID: {newNode?.id}
            </Typography>
            {/* Copy token */}
            <Typography variant="h6" style={{ marginTop: '10px' }}>
                Node Token:
            </Typography>
            <Box style={{ marginTop: '10px', display: 'flex' }}>
                <TextField
                    variant="outlined"
                    value={nodeTokenResponse?.value}
                    // disabled
                />
                <CopyToClipboard text={nodeTokenResponse?.value || ''}>
                    <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'}>
                        <IconButton color="primary" onClick={handleCopy}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                </CopyToClipboard>
            </Box>
            <Button
                variant="contained"
                color="primary"
                style={{ marginTop: '30px' }}
                onClick={() => {
                    setShowSuccessScreen(false)
                    onClose()
                }}
            >
                Close
            </Button>
        </Backdrop>
    )

    return ReactDOM.createPortal(
        <Modal
            open={true}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <div
                style={{
                    backgroundColor: theme.palette.background.paper,
                    padding: '20px',
                    borderRadius: '5px',
                    width: '80%',
                    maxWidth: '600px',
                }}
            >
                <Typography variant="h5" align="center" gutterBottom>
                    Add Node
                </Typography>
                <form>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Role</InputLabel>
                        <Select
                            name="role"
                            value={formData.role}
                            onChange={handleSelectChange}
                        >
                            <MenuItem value="">
                                <em>Select role</em>
                            </MenuItem>
                            <MenuItem value="manager">Manager</MenuItem>
                            <MenuItem value="worker">Worker</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Architecture</InputLabel>
                        <Select
                            name="architecture"
                            value={formData.architecture}
                            onChange={handleSelectChange}
                        >
                            <MenuItem value="">
                                <em>Select architecture</em>
                            </MenuItem>
                            <MenuItem value="amd64">AMD64</MenuItem>
                            <MenuItem value="arm64">ARM64</MenuItem>
                        </Select>
                    </FormControl>
                    <div
                        style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={onClose}
                        >
                            Close
                        </Button>
                    </div>
                </form>
                {showWaitingScreen && waitingScreen}
                {showSuccessScreen && successScreen}
            </div>
        </Modal>,
        document.getElementById('add-node-portal-root')!
    )
}

export default AddNodePanel
