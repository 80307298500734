import { FC, useState } from 'react'
import { ApplicationService } from '../libs/ambient_api/ApplicationService'
import {
    Button,
    Modal,
    TextField,
    Typography,
    Grid,
    Paper,
    Box,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Service } from '../types/TechnicalTypes'

interface EditAppPanelProps {
    onClose: () => void
    application: Service
    token: string
}

const EditAppPanel: FC<EditAppPanelProps> = ({
    onClose,
    application,
    token,
}) => {
    const theme = useTheme()
    const [name, setName] = useState<string>(application.name)
    const [description, setDescription] = useState<string>(
        application.description!
    )
    const [image, setImage] = useState<string>(
        application.requested_service_spec.image
    )
    const [tags, setTags] = useState<string[]>(
        application.requested_service_spec.tags
    )
    const [ports, setPorts] = useState<string[]>(
        application.requested_service_spec.ports
    )
    const [replicas, setReplicas] = useState<number | undefined>(
        application.requested_service_spec.replicas
    )
    const [labels, setLabels] = useState<string[] | undefined>(
        application.requested_service_spec.labels
    )
    const [envVars, setEnvVars] = useState<string[] | undefined>(
        application.requested_service_spec.env_vars
    )
    const [hostname, setHostname] = useState<string | undefined>(
        application.requested_service_spec.hostname
    )
    const [mounts, setMounts] = useState<string[] | undefined>(
        application.requested_service_spec.mounts
    )
    const [networks, setNetworks] = useState<string[] | undefined>(
        application.requested_service_spec.networks
    )

    const handleSave = async () => {
        const applicationService = new ApplicationService(token)
        const currentApplication = await applicationService.getService(
            application.id
        )
        const updatedApplication: Service = {
            ...currentApplication,
            name,
            description,
            desired_state: 'created',
            requested_service_spec: {
                id: currentApplication.requested_service_spec.id,
                image: image,
                tags: tags,
                ports: ports,
                replicas: replicas,
                labels: labels,
                env_vars: envVars,
                hostname: hostname,
                mounts: mounts,
                networks: networks,
            },
        }
        await applicationService.putApp(updatedApplication)
        onClose()
    }

    function parseListString(input: string): string[] {
        console.log('AddApplicationPanel parseListString input:', input)
        if (!input) {
            console.log(
                'AddApplicationPanel parseListString returning empty array'
            )
            return []
        }
        try {
            console.log(
                'AddApplicationPanel parseListString returning parsed array'
            )
            return input.split(',').map((s) => s.trim())
        } catch (e) {
            console.log('AddApplicationPanel parseListString error:', e)
            console.log(
                'AddApplicationPanel parseListString returning empty array'
            )
            return []
        }
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="edit-node-panel"
            aria-describedby="edit-node-panel"
        >
            <Grid
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: theme.palette.background.paper,
                    padding: '20px',
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: theme.shadows[5],
                    maxHeight: '90vh',
                    overflowY: 'auto',
                }}
                alignContent={'center'}
                alignItems={'center'}
                justifyContent={'center'}
                direction={'column'}
            >
                <Typography
                    variant="h6"
                    style={{ padding: '10px', paddingBottom: '30px' }}
                >
                    Edit Application
                </Typography>
                <Box
                    style={{
                        padding: '20px',
                        marginBottom: theme.spacing(2),
                    }}
                >
                    <Grid
                        container
                        direction={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Box
                            border={1}
                            borderRadius={2}
                            textAlign="center"
                            p={2}
                            width={'80%'}
                        >
                            <Box padding={2}>
                                <Typography variant="h6">
                                    General Details
                                </Typography>
                            </Box>
                            <Grid
                                container
                                direction={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                {TextFieldBox(
                                    'Name',
                                    name,
                                    (e) => setName(e.target.value),
                                    theme
                                )}
                                {TextFieldBox(
                                    'Description',
                                    description,
                                    (e) => setDescription(e.target.value),
                                    theme
                                )}
                            </Grid>
                        </Box>
                        <Box
                            border={1}
                            borderRadius={2}
                            textAlign="center"
                            p={2}
                            width={'80%'}
                            marginTop={2}
                        >
                            <Box padding={2}>
                                <Typography variant="h6">
                                    Requested Service Spec
                                </Typography>
                            </Box>
                            <Grid
                                container
                                direction={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                {TextFieldBox(
                                    'Image',
                                    image,
                                    (e) => setImage(e.target.value),
                                    theme
                                )}
                                {TextFieldBox(
                                    'Tags',
                                    tags.join(', '),
                                    (e) =>
                                        setTags(
                                            parseListString(e.target.value)
                                        ),
                                    theme
                                )}
                                {TextFieldBox(
                                    'Ports',
                                    ports.join(', '),
                                    (e) =>
                                        setPorts(
                                            parseListString(e.target.value)
                                        ),
                                    theme
                                )}
                                {TextFieldBox(
                                    'Replicas',
                                    replicas ? replicas.toString() : '',
                                    (e) =>
                                        setReplicas(parseInt(e.target.value)),
                                    theme
                                )}
                                {TextFieldBox(
                                    'Labels',
                                    labels ? labels.join(', ') : '',
                                    (e) =>
                                        setLabels(
                                            parseListString(e.target.value)
                                        ),
                                    theme
                                )}
                                {TextFieldBox(
                                    'Env Vars',
                                    envVars ? envVars.join(', ') : '',
                                    (e) =>
                                        setEnvVars(
                                            parseListString(e.target.value)
                                        ),
                                    theme
                                )}
                                {TextFieldBox(
                                    'Hostname',
                                    hostname ? hostname : '',
                                    (e) => setHostname(e.target.value),
                                    theme
                                )}
                                {TextFieldBox(
                                    'Mounts',
                                    mounts ? mounts.join(', ') : '',
                                    (e) =>
                                        setMounts(
                                            parseListString(e.target.value)
                                        ),
                                    theme
                                )}
                                {TextFieldBox(
                                    'Networks',
                                    networks ? networks.join(', ') : '',
                                    (e) =>
                                        setNetworks(
                                            parseListString(e.target.value)
                                        ),
                                    theme
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
                <Grid
                    container
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Button
                        variant="contained"
                        onClick={handleSave}
                        style={{ marginTop: theme.spacing(2) }}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default EditAppPanel

const TextFieldBox = (
    label: string,
    value: string,
    onChange: (e: any) => void,
    theme: any
) => {
    return (
        <Box style={{ padding: '10px' }}>
            <TextField
                label={label}
                value={value}
                onChange={onChange}
                style={{ marginBottom: theme.spacing(2) }}
            />
        </Box>
    )
}
