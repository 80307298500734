import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Grid,
    Typography,
    Box,
    Dialog,
    Button,
} from '@mui/material'
import { Service, ServiceNodeRelationship } from '../../types/TechnicalTypes'
import CodeIcon from '@mui/icons-material/Code'
import { CopyBlock } from 'react-code-blocks'
import { useState } from 'react'
import { min, set } from 'date-fns'

export const deploymentNeeded = (
    service: Service,
    deployments: ServiceNodeRelationship[]
) => {
    if (!service || !deployments) {
        return false
    }
    // if service state is created, return true
    if (service.desired_state === 'created') {
        return true
    }

    // if service state is deployed, ensure that all deployments are in a deployed state and succesful status
    if (service.desired_state === 'deployed') {
        for (const deployment of deployments) {
            if (!deployment) {
                return true
            }
            if (
                deployment.state !== 'deployed' ||
                deployment.status !== 'success'
            ) {
                return true
            }
        }
    }

    // default return false
    return false
}

export const DashboardTile = (title: string, value: string) => {
    return (
        <Grid item xs={12} sm={6} md={2}>
            <Box border={1} borderRadius={2} textAlign="center" p={2}>
                <Typography variant="h4">{value}</Typography>
                <Typography variant="subtitle1">{title}</Typography>
            </Box>
        </Grid>
    )
}
const AppDeploymentTableHeaderCell = ({ header }: { header: string }) => {
    return <TableCell>{header}</TableCell>
}

const AppDeploymentTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <AppDeploymentTableHeaderCell header="Node ID" />
                <AppDeploymentTableHeaderCell header="State" />
                <AppDeploymentTableHeaderCell header="Status" />
                <AppDeploymentTableHeaderCell header="Error" />
                <AppDeploymentTableHeaderCell header="Docker Config" />
            </TableRow>
        </TableHead>
    )
}

const AppDeploymentTableRow = ({
    serviceDeployment,
    showDockerAttrsOnClick,
}: {
    serviceDeployment: ServiceNodeRelationship
    showDockerAttrsOnClick: (event: any) => void
}) => {
    return (
        <TableRow>
            <TableCell>{serviceDeployment.node_id}</TableCell>
            <TableCell>{serviceDeployment.state}</TableCell>
            <TableCell>{serviceDeployment.status}</TableCell>
            <TableCell>{serviceDeployment.error}</TableCell>
            <TableCell>
                <IconButton color="secondary" onClick={showDockerAttrsOnClick}>
                    <CodeIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

const AppDeploymentTableBody = ({
    deployments,
    showDockerAttrsOnClick,
}: {
    deployments: ServiceNodeRelationship[]
    showDockerAttrsOnClick: (event: any) => void
}) => {
    return (
        <TableBody>
            {deployments.map((deployment, index) => {
                return (
                    <AppDeploymentTableRow
                        key={index}
                        serviceDeployment={deployment}
                        showDockerAttrsOnClick={showDockerAttrsOnClick}
                    />
                )
            })}
        </TableBody>
    )
}

interface AppDeploymentTableProps {
    deployments: ServiceNodeRelationship[]
}

export const AppDeploymentTable = ({
    deploymentProps,
}: {
    deploymentProps: AppDeploymentTableProps
}) => {
    const [showDockerConfig, setShowDockerConfig] = useState(false)
    const [selectedDeployment, setSelectedDeployment] =
        useState<ServiceNodeRelationship | null>(null)
    const showDockerAttrsOnClick = (event: any) => {
        setShowDockerConfig(true)
        const index = event.currentTarget.closest('tr').rowIndex - 1
        // setSelectedDeployment(mock_deployments[index]);
        setSelectedDeployment(deploymentProps.deployments[index])
    }

    return (
        <Paper>
            <Table>
                <AppDeploymentTableHeader />
                <AppDeploymentTableBody
                    deployments={deploymentProps.deployments}
                    showDockerAttrsOnClick={showDockerAttrsOnClick}
                />
            </Table>
            {showDockerConfig && (
                <DockerConfigDialog
                    dockerConfig={
                        selectedDeployment?.docker_service_attrs
                            ? selectedDeployment.docker_service_attrs
                            : ''
                    }
                    onCloseClick={() => setShowDockerConfig(false)}
                />
            )}
        </Paper>
    )
}

const DockerConfigDialog = ({
    dockerConfig,
    onCloseClick,
}: {
    dockerConfig: string | object
    onCloseClick: any
}) => {
    const configString =
        typeof dockerConfig === 'string'
            ? dockerConfig
            : JSON.stringify(dockerConfig, null, 2)

    return (
        <Dialog open={true} onClose={onCloseClick}>
            <Grid container justifyContent={'center'} padding={2}>
                <Typography variant="h6">Docker Config</Typography>
            </Grid>
            <Box minWidth={'500px'} margin={2} padding={2}>
                <CopyBlock
                    text={configString}
                    language={'json'}
                    showLineNumbers={false}
                    customStyle={{
                        padding: '10px',
                        borderRadius: '5px',
                        backgroundColor: 'white',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                />
            </Box>
            <Button onClick={onCloseClick} style={{ margin: '10px' }}>
                Close
            </Button>
        </Dialog>
    )
}
