import {
    Service,
    Request,
    ServiceNodeRelationship,
} from '../../types/TechnicalTypes'
import { HttpError } from '../../types/Errors'
import {
    GetServicesResponse,
    GetRequestsResponse,
    DeployServiceResponse,
    GetServiceNodeRelationshipsResponse,
} from '../../types/ApiResponses'
import { CreateServiceRequest } from '../../types/ApiRequests'
import BaseService from './BaseService'

export class ApplicationService extends BaseService {
    public async createService(
        service: CreateServiceRequest
    ): Promise<Service> {
        // console.log('Creating service...')
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/services`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(service),
            }
        )

        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }

        const parsedResponse: Service = await response.json()
        return parsedResponse
    }

    public async deployService(
        serviceId: number
    ): Promise<DeployServiceResponse> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/services/${serviceId}/deploy`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )
        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }
        const parsedResponse: DeployServiceResponse = await response.json()
        return parsedResponse
    }

    public async getServices(): Promise<Service[]> {
        // FIXME: [SD-94] currently not returning all services
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/services`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )
        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }
        const parsedResponse: GetServicesResponse = await response.json()
        return parsedResponse.results as Service[]
    }

    public async getService(serviceId: number | string): Promise<Service> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/services/${serviceId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )
        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }
        const parsedResponse: Service = await response.json()
        return parsedResponse
    }

    public async deleteService(serviceId: number): Promise<void> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/services/${serviceId}`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )
        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }
    }

    public async patchApplication(
        serviceId: number,
        service: Partial<Service>
    ): Promise<Service> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/services/${serviceId}`,
            {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(service),
            }
        )
        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }
        const parsedResponse: Service = await response.json()
        return parsedResponse
    }

    public async addNodeToApp(
        serviceId: number,
        nodeId: number
    ): Promise<void> {
        console.log('Adding node to app...')
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/services/${serviceId}/nodes/?node_id=${nodeId}`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )
        if (!response.ok) {
            console.log('Error adding node to app')
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        } else {
            console.log(
                'Added node to app. Response: ' + JSON.stringify(response)
            )
        }
    }

    // returns true if the name is available
    public async checkAppNameAvailability(name: string): Promise<boolean> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/services/?name=${name}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )
        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }
        const parsedResponse: GetServicesResponse = await response.json()
        return parsedResponse.results.length === 0
    }

    public async getRequestsForApp(appId: number): Promise<Request[]> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/services/${appId}/requests`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )
        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }
        const parsedResponse: GetRequestsResponse = await response.json()
        return parsedResponse.results as Request[]
    }

    public async getNodeRelationships(
        appId: number
    ): Promise<GetServiceNodeRelationshipsResponse> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/services/${appId}/node_relationships`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                },
            }
        )
        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }
        const parsedResponse: GetServiceNodeRelationshipsResponse =
            await response.json()
        return parsedResponse
    }

    public async putApp(app: Service): Promise<Service> {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/services/${app.id}`,
            {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(app),
            }
        )
        if (!response.ok) {
            if (response.status === 401) {
                throw new HttpError('Unauthorized', 401)
            }
            throw new HttpError(response.statusText, response.status)
        }
        const parsedResponse: Service = await response.json()
        return parsedResponse
    }
}
