import React, { useState } from 'react'
import {
    AppBar,
    Toolbar,
    Grid,
    Typography,
    IconButton,
    Box,
    Paper,
    Button,
    Divider,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import SidebarMenu from './SidebarMenu'
import PersonIcon from '@mui/icons-material/Person'
import { useTheme as useThemeContext } from '../ThemeContext'
import { User } from '../types/User'

const TopToolBar: React.FC = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    const [showUserMenu, setShowUserMenu] = useState<boolean>(false)
    const { user, loginWithRedirect } = useAuth0()
    const { themeMode, toggleTheme } = useThemeContext()

    const loadUserSettings = () => {
        const userString = localStorage.getItem('user')
        if (!userString) {
            return
        }
        const user: User = JSON.parse(userString || '{}')
        const darkMode = user.preferences!.dark_mode
        // console.log('Dark mode: ' + darkMode)
        if (darkMode && themeMode === 'light') {
            console.log('Setting theme to dark')
            toggleTheme()
        }
    }

    loadUserSettings()

    const userMenu = (
        <Paper
            elevation={5}
            style={{ position: 'absolute', top: 50, right: 10, zIndex: 10 }}
        >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
            >
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        align="center"
                        style={{ padding: '10px', paddingLeft: '20px' }}
                    >
                        {user?.name}
                    </Typography>
                    <Typography
                        variant="body2"
                        align="center"
                        style={{
                            padding: '1px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                        }}
                    >
                        {user?.email}
                    </Typography>
                    <Divider />
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{ padding: '5px', paddingLeft: '20px' }}
                >
                    <Button onClick={() => navigate('/preferences')}>
                        <Typography
                            variant="body1"
                            align="center"
                            style={{ padding: '10px', paddingLeft: '20px' }}
                            color={'textPrimary'}
                        >
                            Preferences
                        </Typography>
                    </Button>
                    <Divider />
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{ padding: '5px', paddingLeft: '20px' }}
                >
                    <Button onClick={() => navigate('/settings')}>
                        <Typography
                            variant="body1"
                            align="center"
                            style={{ padding: '10px', paddingLeft: '20px' }}
                            color={'textPrimary'}
                        >
                            Settings
                        </Typography>
                    </Button>
                    <Divider />
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        padding: '5px',
                        paddingLeft: '20px',
                        alignContent: 'right',
                    }}
                >
                    <Button onClick={() => loginWithRedirect()}>
                        <Typography>Log Out</Typography>
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    )

    return (
        <AppBar
            position="static"
            style={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.primary.contrastText,
            }}
        >
            <Toolbar>
                <SidebarMenu />
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '5%',
                            padding: '10px',
                        }}
                    />
                    <Typography
                        variant="h6"
                        color={'textSecondary'}
                        style={{
                            flexGrow: 1,
                        }}
                    >
                        Ambient Cloud
                    </Typography>
                    <IconButton
                        color="inherit"
                        onClick={() => setShowUserMenu(!showUserMenu)}
                    >
                        <PersonIcon color="secondary" />
                    </IconButton>
                </Grid>
                {showUserMenu && userMenu}
            </Toolbar>
        </AppBar>
    )
}

export default TopToolBar
