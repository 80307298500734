import { useEffect, useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    IconButton,
    Grid,
    Typography,
    Box,
    Tooltip,
    Dialog,
    Button,
    Icon,
    Alert,
    ClickAwayListener,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import AddBoxIcon from '@mui/icons-material/AddBox'
import EditIcon from '@mui/icons-material/Edit'
import { ApplicationService } from '../../libs/ambient_api/ApplicationService'
import { useNavigate } from 'react-router-dom'
import EditAppPanel from '../../components/EditAppPanel'
import { Service, ServiceNodeRelationship } from '../../types/TechnicalTypes'
import AddNodeToAppPanel from '../../components/AddNodeToAppPanel'
import TopToolBar from '../../components/AppToolBar'
import RefreshIcon from '@mui/icons-material/Refresh'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown'
import AspectRatioIcon from '@mui/icons-material/AspectRatio'
import CodeIcon from '@mui/icons-material/Code'
import {
    AppDeploymentTable,
    DashboardTile,
    deploymentNeeded,
} from './Components'
import App from '../../App'
import { ErrorAlert } from '../../components/ErrorAlert'
import DownloadIcon from '@mui/icons-material/Download'

const ApplicationsPage: React.FC = () => {
    const applicationService = new ApplicationService('dummy_token')
    const [refresh, setRefresh] = useState<boolean>(false)
    const headers = [
        'Name',
        'ID',
        'Image',
        'Desired State',
        'Ports',
        'Tags',
        'Replicas',
    ]
    const [showEditAppPanel, setShowEditAppPanel] = useState<boolean>(false)
    const [currentService, setCurrentService] = useState<Service | null>(null)
    const [showAddNodeToAppPanel, setShowAddNodeToAppPanel] =
        useState<boolean>(false)
    const navigate = useNavigate()
    const [apps, setApps] = useState<Service[]>([])
    const [showDeleteAppConfirmation, setShowDeleteAppConfirmation] =
        useState<boolean>(false)
    const [selectedApp, setSelectedApp] = useState<Service | null>(null)
    const [selectedAppDeployments, setSelectedAppDeployments] = useState<
        ServiceNodeRelationship[]
    >([])
    const [showAppDeploymentsPanel, setShowAppDeploymentsPanel] =
        useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [successMessage, setSuccessMessage] = useState<string>('')
    const [appDeploymentsObj, setAppDeploymentsObj] = useState<{
        [key: number]: ServiceNodeRelationship[]
    }>({})

    // auth useEffect
    const [token, setToken] = useState<string>('')
    useEffect(() => {
        const token = localStorage.getItem('access_token')
        console.log('Token: ' + token)
        if (token) {
            setToken(token)
            applicationService.setToken(token)
        } else {
            navigate('/')
        }
    }, [navigate])

    // fetch applications useEffect
    useEffect(() => {
        const fetchApplications = async () => {
            if (!token) {
                // console.log('No token')
                return
            }
            try {
                applicationService.setToken(token)
                const applications = await applicationService.getServices()
                setApps(applications)

                // create an object of app_id -> deployments
                const appDeployments: {
                    [key: number]: ServiceNodeRelationship[]
                } = {}
                for (const app of applications) {
                    const deployments =
                        await applicationService.getNodeRelationships(app.id)
                    appDeployments[app.id] = deployments.results
                }
                setAppDeploymentsObj(appDeployments)
            } catch (error) {
                console.error('Error fetching applications: ' + error)
                setError('Error fetching applications: ' + error)
                return
            }
        }
        // console.log('Fetching applications...')
        fetchApplications()
    }, [refresh, token])

    const handleEditClick = async (app_id: number) => {
        const serviceData = apps.find((app) => app.id === app_id)
        // Set this service data to state, then open the edit panel with this state
        setCurrentService(serviceData!)
        setShowEditAppPanel(true)
    }

    const handleDeleteClick = async (app_id: number) => {
        const serviceData = apps.find((app) => app.id === app_id)
        // Set this service data to state, then open the edit panel with this state
        setSelectedApp(serviceData!)
        setShowDeleteAppConfirmation(true)
    }

    const handleDelete = async (appId: number) => {
        if (!token) {
            // console.log('No token')
            return
        }
        applicationService.setToken(token)
        try {
            await applicationService.deleteService(appId)
        } catch {
            setError('Error deleting application')
            return
        }
        // console.log('Deleted application: ' + application)
        setRefresh(!refresh)
        setSuccessMessage('Application deleted successfully')
    }

    const handleAddNodeToApp = async (appId: number) => {
        const application = apps.find((app) => app.id === appId)
        setCurrentService(application!)
        setShowAddNodeToAppPanel(true)
    }

    // A simple banner displaying important application metrics
    const DashBoardBanner = () => {
        return (
            <Grid item xs={10}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    marginTop={4}
                >
                    <Grid item xs={12}>
                        <Paper>
                            <Typography variant="h5" align="center" padding={2}>
                                Application Metrics
                            </Typography>
                            <Grid
                                container
                                direction="row"
                                justifyContent={'space-between'}
                                padding={2}
                                paddingBottom={4}
                            >
                                <Box></Box>
                                {DashboardTile(
                                    'Total Applications',
                                    apps.length.toString()
                                )}
                                {DashboardTile(
                                    'Running Applications',
                                    apps
                                        .filter(
                                            (app) =>
                                                app.desired_state === 'deployed'
                                        )
                                        .length.toString()
                                )}
                                {DashboardTile(
                                    'Imported Applications',
                                    apps
                                        .filter(
                                            (app) =>
                                                app.desired_state === 'created'
                                        )
                                        .length.toString()
                                )}
                                <Box></Box>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const AppTableRow = (app: Service) => {
        if (!appDeploymentsObj) {
            return null
        }
        return (
            <TableRow>
                <TableCell padding="checkbox">
                    <Box padding={2}>
                        <Tooltip title="View App Deployments" arrow>
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    handleExpandDeploymentClick(app.id)
                                }}
                            >
                                <AspectRatioIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </TableCell>
                <TableCell>{app.name}</TableCell>
                <TableCell>{app.id}</TableCell>
                <TableCell>
                    {app.requested_service_spec.image.split('@')[0]}
                </TableCell>
                <TableCell>{app.desired_state}</TableCell>
                <TableCell>{app.requested_service_spec.ports}</TableCell>
                <TableCell>{app.requested_service_spec.tags}</TableCell>
                <TableCell>{app.requested_service_spec.replicas}</TableCell>
                <TableCell>
                    <Tooltip title="Edit" arrow>
                        <IconButton
                            onClick={() => {
                                handleEditClick(app.id)
                            }}
                        >
                            <EditIcon color="secondary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add Node" arrow>
                        <IconButton
                            onClick={() => {
                                handleAddNodeToApp(app.id)
                            }}
                        >
                            <AddBoxIcon color="secondary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View App Details" arrow>
                        <IconButton
                            onClick={() => {
                                navigate('/applications/' + app.id)
                            }}
                        >
                            <ArrowOutwardIcon color="secondary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete App" arrow>
                        <IconButton
                            onClick={() => {
                                handleDeleteClick(app.id)
                            }}
                        >
                            <DeleteIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    {deploymentNeeded(app, appDeploymentsObj[app.id]) && (
                        <Tooltip title="Deploy App" arrow>
                            <IconButton
                                onClick={() => {
                                    handleDeployClick(app.id)
                                }}
                            >
                                <DownloadIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                    )}
                </TableCell>
            </TableRow>
        )
    }

    const AppsTable = () => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                marginTop={4}
            >
                <Grid item xs={10}>
                    <Paper>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                        >
                            <Typography variant="h5" padding={2} marginLeft={4}>
                                Applications
                            </Typography>
                            <Box padding={2}>
                                <IconButton
                                    onClick={() => {
                                        setRefresh(!refresh)
                                    }}
                                >
                                    <RefreshIcon color="primary" />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={10}>
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Box padding={2} marginLeft={1}>
                                            <AspectRatioIcon />
                                        </Box>
                                    </TableCell>
                                    {headers.map((header, index) => (
                                        <TableCell key={index}>
                                            {header}
                                        </TableCell>
                                    ))}
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {apps.map((app) => (
                                    <AppTableRow key={app.id} {...app} />
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    const DeleteAppConfirmation = () => {
        return (
            <Dialog open={true}>
                <Typography variant="h6" padding={4}>
                    Are you sure you want to delete this application?
                </Typography>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    padding={2}
                >
                    <Button
                        onClick={() => {
                            setShowDeleteAppConfirmation(false)
                        }}
                        startIcon={<CancelIcon />}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleDelete(selectedApp!.id)
                            setShowDeleteAppConfirmation(false)
                        }}
                        startIcon={<CheckCircleIcon />}
                    >
                        Confirm
                    </Button>
                </Grid>
            </Dialog>
        )
    }

    const handleExpandDeploymentClick = async (appId: number) => {
        setSelectedApp(apps.find((app) => app.id === appId)!)

        // get the app's deployments from backend
        try {
            applicationService.setToken(token)
            const deployments =
                await applicationService.getNodeRelationships(appId)
            setSelectedAppDeployments(deployments.results)
        } catch (error) {
            console.error('Error getting deployments for app: ' + error)
            setError('Error getting deployments for app: ' + error)
            return
        }

        // set showAppDeploymentsPanel to true
        setShowAppDeploymentsPanel(true)
    }

    const AppDeploymentDialog = () => {
        return (
            <Dialog open={showAppDeploymentsPanel}>
                <Grid container direction={'column'} padding={4}>
                    <Grid container direction={'row'} justifyContent={'center'}>
                        <Typography variant="h6" padding={2}>
                            Deployments for{' '}
                            <Typography sx={{ fontFamily: 'Monospace' }}>
                                {selectedApp!.name}
                            </Typography>
                        </Typography>
                    </Grid>
                    <AppDeploymentTable
                        deploymentProps={{
                            deployments: selectedAppDeployments,
                        }}
                    />
                    <Box padding={2} />
                    <Button
                        startIcon={<CancelIcon />}
                        onClick={() => setShowAppDeploymentsPanel(false)}
                    >
                        Close
                    </Button>
                </Grid>
            </Dialog>
        )
    }

    const handleDeployClick = async (appId: number) => {
        if (!token) {
            return
        }

        applicationService.setToken(token)

        const respone = await applicationService.getNodeRelationships(appId)
        const deployments = respone.results
        setSelectedAppDeployments(deployments)
        setSelectedApp(apps.find((app) => app.id === appId)!)
        setShowDeployConfirmation(true)
    }

    const handleDeploymentConfirmClick = async (appId: number) => {
        if (!token) {
            return
        }

        applicationService.setToken(token)
        const app = apps.find((app) => app.id === appId)
        app!.desired_state = 'deployed'
        await applicationService.putApp(app!)
        const response = await applicationService.deployService(appId)
        console.log('Deployment response: ' + JSON.stringify(response))
        setSuccessMessage('Deployment started successfully')
        setRefresh(!refresh)
    }

    const [showDeployConfirmation, setShowDeployConfirmation] =
        useState<boolean>(false)
    const DeployConfirmation = () => {
        return (
            <Dialog open={true}>
                <Typography variant="h6" padding={4}>
                    Please confirm you'd like to start the following
                    deployments:
                </Typography>
                {selectedAppDeployments.length > 0 && (
                    <>
                        <AppDeploymentTable
                            deploymentProps={{
                                deployments: selectedAppDeployments,
                            }}
                        />
                    </>
                )}
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    padding={2}
                >
                    <Button
                        onClick={() => {
                            setShowDeployConfirmation(false)
                        }}
                        startIcon={<CancelIcon />}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleDeploymentConfirmClick(selectedApp!.id)
                            setShowDeployConfirmation(false)
                            setSelectedAppDeployments([])
                        }}
                        startIcon={<CheckCircleIcon />}
                    >
                        Confirm
                    </Button>
                </Grid>
            </Dialog>
        )
    }

    const SuccessAlert = () => {
        return (
            <ClickAwayListener onClickAway={() => setSuccessMessage('')}>
                <Alert
                    icon={<CheckCircleIcon fontSize="inherit" />}
                    severity="success"
                    style={{
                        position: 'fixed',
                        bottom: '10px',
                        left: '40%',
                        zIndex: 1000,
                    }}
                >
                    {successMessage}
                </Alert>
            </ClickAwayListener>
        )
    }

    return (
        <div>
            <TopToolBar />
            <Grid container direction="row" justifyContent="center">
                <DashBoardBanner />
                <AppsTable />
            </Grid>
            {showEditAppPanel && (
                <EditAppPanel
                    application={currentService!}
                    onClose={() => {
                        setShowEditAppPanel(false)
                    }}
                    token={token}
                />
            )}
            {showAddNodeToAppPanel && (
                <AddNodeToAppPanel
                    application={currentService!}
                    onClose={() => {
                        setShowAddNodeToAppPanel(false)
                    }}
                    token={token}
                />
            )}
            {showDeleteAppConfirmation && <DeleteAppConfirmation />}
            {showAppDeploymentsPanel && <AppDeploymentDialog />}
            {showDeployConfirmation && <DeployConfirmation />}
            {error && (
                <ErrorAlert props={{ message: error, setError: setError }} />
            )}
            {successMessage && <SuccessAlert />}
        </div>
    )
}

export default ApplicationsPage
