import { useEffect, useState } from 'react'
import {
    Button,
    Grid,
    Typography,
    Paper,
    Box,
    IconButton,
    AppBar,
    Chip,
    Tooltip,
    ButtonGroup,
    Card,
} from '@mui/material'
import { Collapse } from '@mui/material'
import { Divider } from '@mui/material'
import { useNavigate } from 'react-router'
import { ApplicationService } from '../libs/ambient_api/ApplicationService'
import { Service, Request } from '../types/TechnicalTypes'
import { LoadingScreen } from '../components/HomeScreenComp'
import { HomeButton } from '../components/HomeButton'
import EditIcon from '@mui/icons-material/Edit'
import EditAppPanel from '../components/EditAppPanel'
import DownloadDoneIcon from '@mui/icons-material/DownloadDone'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import PendingIcon from '@mui/icons-material/Pending'
import ArticleIcon from '@mui/icons-material/Article'
import DownloadIcon from '@mui/icons-material/Download'
import { DeployServiceResponse } from '../types/ApiResponses'
import TopToolBar from '../components/AppToolBar'

const AppInfoPage: React.FC = () => {
    const navigate = useNavigate()
    const [token, setToken] = useState<string>('')
    const [app, setApp] = useState<Service | null>(null)
    const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(true)
    const [showEditAppPanel, setShowEditAppPanel] = useState<boolean>(false)
    const [showRequests, setShowRequests] = useState<boolean>(false)
    const [requests, setRequests] = useState<Request[]>([])
    const [showDeploymentPanel, setShowDeploymentPanel] =
        useState<boolean>(false)
    const [deploymentResponse, setDeploymentResponse] =
        useState<DeployServiceResponse | null>(null)

    // auth useEffect
    useEffect(() => {
        const token = localStorage.getItem('access_token')
        if (token) {
            setToken(token)
        } else {
            navigate('/home')
        }
    }, [navigate])

    // make API call to get node info
    useEffect(() => {
        if (!token) {
            return
        }
        // get node ID from URL
        const url = window.location.pathname
        console.log('URL:', url)
        const appId = url.split('/')[2]
        console.log('App ID:', appId)
        const appService = new ApplicationService(token)
        const fetchNode = async () => {
            const fetchedApp = await appService.getService(appId)
            console.log('Fetched app:', JSON.stringify(fetchedApp))
            setApp(fetchedApp)
        }
        fetchNode()
        setShowLoadingScreen(false)
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            const appService = new ApplicationService(token)
            const requests = await appService.getRequestsForApp(app!.id)
            setRequests(requests)
        }
        if (showRequests) {
            fetchData()
        }
    }, [showRequests])

    const deploymentPanel = () => {
        // just a simple panel with a close button that displays the location of the deployed app and a message
        return (
            <Paper>
                <Grid container direction={'column'} alignItems={'center'}>
                    <Grid item padding={'10px'}>
                        <Typography variant="h4" gutterBottom>
                            Deployment Complete
                        </Typography>
                    </Grid>
                    <Divider />
                    <Grid item padding={'10px'}>
                        <Typography variant="body1" gutterBottom>
                            Your app has been deployed. You can access it at:
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {deploymentResponse?.location}
                        </Typography>
                        <Box m={5} />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowDeploymentPanel(false)}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    const handleDeployApp = async () => {
        setShowLoadingScreen(true)
        const appService = new ApplicationService(token)
        const response = await appService.deployService(app!.id)
        setDeploymentResponse(response)
        console.log('Response:', response)
        setShowLoadingScreen(false)
        setShowDeploymentPanel(true)
    }

    const viewRequestsComponent = () => {
        return (
            <div>
                <Box m={5} />
                <Paper>
                    <Grid container direction={'column'} alignItems={'center'}>
                        <Grid item padding={'10px'}>
                            <Typography variant="h4" gutterBottom>
                                Requests
                            </Typography>
                        </Grid>
                        <Divider />
                        <Grid container direction={'row'}>
                            {requests.map((request) => (
                                <Grid item>
                                    <Card
                                        style={{
                                            margin: '20px',
                                            padding: '20px',
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            Request ID: {request.id}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            Status: {request.status}
                                        </Typography>
                                        <Typography variant="h6" gutterBottom>
                                            Notes:{' '}
                                            {request.notes!.map((note) => (
                                                <Paper
                                                    style={{
                                                        padding: '20px',
                                                        margin: '20px',
                                                    }}
                                                >
                                                    <Typography variant="body1">
                                                        {note}
                                                    </Typography>
                                                </Paper>
                                            ))}
                                        </Typography>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        )
    }

    // render screen
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
            style={{ font: 'sans' }}
        >
            {showLoadingScreen && <LoadingScreen />}
            {showDeploymentPanel && deploymentPanel()}
            <TopToolBar />
            <Grid item xs={8} marginTop={'20px'}>
                {/* <Paper elevation={3} style={{ padding: '20px' }}> */}
                <Grid container direction="row" justifyContent="space-between">
                    {/* <AppBar
                        style={{ alignItems: 'center', paddingTop: '20px' }}
                    >
                        <HomeButton />
                        <Typography variant="h4" gutterBottom>
                            Application - {app?.name}
                        </Typography>
                    </AppBar> */}
                    {showEditAppPanel && (
                        <EditAppPanel
                            onClose={() => setShowEditAppPanel(false)}
                            application={app!}
                            token={token}
                        />
                    )}
                </Grid>
                <Box m={5} />
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Grid container direction={'row'}>
                        <Grid item padding="5px" width={'100%'}>
                            <Grid
                                container
                                direction={'row'}
                                justifyContent={'space-between'}
                            >
                                <Typography variant="h4" gutterBottom>
                                    App Information
                                </Typography>
                                <ButtonGroup
                                    variant="contained"
                                    aria-label="Basic button group"
                                >
                                    <Tooltip title="Edit App Info">
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                setShowEditAppPanel(true)
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="View Requests">
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                setShowRequests(!showRequests)
                                            }}
                                        >
                                            <ArticleIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Deploy App">
                                        <IconButton color="secondary">
                                            <DownloadIcon
                                                onClick={handleDeployApp}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </ButtonGroup>
                            </Grid>
                            <Box m={5} />
                        </Grid>
                        <Grid item padding={'5px'} width={'45%'}>
                            <Box>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    fontWeight={'bold'}
                                >
                                    Name{' '}
                                    <Typography variant="body1">
                                        {app?.name}
                                    </Typography>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item padding={'5px'} width={'45%'}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                fontWeight={'bold'}
                            >
                                Description{' '}
                                <Typography variant="body1">
                                    {app?.description}
                                </Typography>
                            </Typography>
                        </Grid>
                        {/* <Grid item padding={'5px'} width={'45%'}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                fontWeight={'bold'}
                            >
                                Status <Box m={1} />
                                <Tooltip title={app?.status}>
                                    {app?.status === 'success' ? (
                                        <CheckCircleIcon />
                                    ) : app?.status === 'error' ? (
                                        <CancelIcon />
                                    ) : (
                                        <PendingIcon />
                                    )}
                                </Tooltip>
                            </Typography>
                        </Grid> */}
                        <Grid item padding={'5px'} width={'45%'}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                fontWeight={'bold'}
                            >
                                State{' '}
                                <Typography variant="body1">
                                    {app?.desired_state}
                                </Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                {showRequests && viewRequestsComponent()}
                <Box m={5} />
                <Paper>
                    <Grid container direction={'row'} padding={'20px'}>
                        <Grid item padding={'5px'} width={'100%'}>
                            <Grid
                                container
                                direction={'column'}
                                alignItems={'center'}
                            >
                                <Typography variant="h4" gutterBottom>
                                    Resource Data
                                </Typography>
                                <Box m={2} />
                            </Grid>
                        </Grid>
                        <Grid item padding={'5px'} width={'45%'}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                fontWeight={'bold'}
                            >
                                Image{' '}
                                <Typography fontFamily={'monospace'}>
                                    {app?.requested_service_spec.image}
                                </Typography>
                            </Typography>
                        </Grid>
                        <Grid item padding={'5px'} width={'45%'}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                fontWeight={'bold'}
                            >
                                Ports
                                {app?.requested_service_spec.ports.map(
                                    (port) => (
                                        <Chip
                                            label={port}
                                            style={{ margin: '5px' }}
                                        />
                                    )
                                )}
                            </Typography>
                        </Grid>
                        <Grid item padding={'5px'} width={'45%'}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                fontWeight={'bold'}
                            >
                                Tags:
                                {app?.requested_service_spec.tags.map((tag) => (
                                    <Chip
                                        label={tag}
                                        style={{ margin: '5px' }}
                                    />
                                ))}
                            </Typography>
                        </Grid>
                        <Grid item padding={'5px'} width={'45%'}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                fontWeight={'bold'}
                            >
                                Replicas{' '}
                                <Typography fontFamily={'monospace'}>
                                    {app?.requested_service_spec.replicas}
                                </Typography>
                            </Typography>
                        </Grid>
                        <Grid item padding={'5px'} width={'45%'}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                fontWeight={'bold'}
                            >
                                Labels
                                {app?.requested_service_spec.labels ? (
                                    app?.requested_service_spec.labels.map(
                                        (label) => (
                                            <Chip
                                                label={label}
                                                style={{ margin: '5px' }}
                                            />
                                        )
                                    )
                                ) : (
                                    <Typography variant="body1">
                                        No labels
                                    </Typography>
                                )}
                            </Typography>
                        </Grid>
                        <Grid item padding={'5px'} width={'45%'}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                fontWeight={'bold'}
                            >
                                Env Variables
                                {app?.requested_service_spec.env_vars ? (
                                    app?.requested_service_spec.env_vars.map(
                                        (env) => (
                                            <Chip
                                                label={env}
                                                style={{ margin: '5px' }}
                                            />
                                        )
                                    )
                                ) : (
                                    <Typography variant="body1">
                                        No env variables
                                    </Typography>
                                )}
                            </Typography>
                        </Grid>
                        <Grid item padding={'5px'} width={'45%'}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                fontWeight={'bold'}
                            >
                                Hostname{' '}
                                <Typography fontFamily={'monospace'}>
                                    {app?.requested_service_spec.hostname}
                                </Typography>
                            </Typography>
                        </Grid>
                        <Grid item padding={'5px'} width={'45%'}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                fontWeight={'bold'}
                            >
                                Mounts
                                {app?.requested_service_spec.mounts ? (
                                    app?.requested_service_spec.mounts.map(
                                        (mount) => (
                                            <Chip
                                                label={mount}
                                                style={{ margin: '5px' }}
                                            />
                                        )
                                    )
                                ) : (
                                    <Typography variant="body1">
                                        No mounts
                                    </Typography>
                                )}
                            </Typography>
                        </Grid>
                        <Grid item padding={'5px'} width={'45%'}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                fontWeight={'bold'}
                            >
                                Networks
                                {app?.requested_service_spec.networks ? (
                                    app?.requested_service_spec.networks.map(
                                        (network) => (
                                            <Chip
                                                label={network}
                                                style={{ margin: '5px' }}
                                            />
                                        )
                                    )
                                ) : (
                                    <Typography variant="body1">
                                        No networks
                                    </Typography>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default AppInfoPage
